import { languagePkg } from "@/locale";

//将{key：number，value：number}的数据转换为[number,number]的数据
export const removeKeyAndValueNumber = (data: any) => {
  if (!Array.isArray(data)) {
    return [];
  }
  const newFormatted: any[] = [];
  Object.values(data).forEach((t) => {
    newFormatted.push([Number(t.key), Number(t.value)]);
  });
  return newFormatted;
};

export const transformFlow = (value: number, len = 1000) => {
  if (!value && typeof value !== "number") {
    return `-`;
  }
  const sign = value > 0 ? "" : "0";
  const data = Math.abs(value);
  let size = "";
  if (data < len) {
    size = data.toFixed(2) + "B";
  } else if (data < len * len) {
    size = (data / len).toFixed(2) + "KB";
  } else if (data < len * len * len) {
    size = (data / (len * len)).toFixed(2) + "MB";
  } else {
    size = (data / (len * len * len)).toFixed(2) + "GB";
  }
  let sizeStr = size + "";
  let index = sizeStr.indexOf(".");
  let dou = sizeStr.substr(index + 1, 2);
  if (dou === "00") {
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
  }

  return `${sign}${size}`;
};

//转换带宽
export const chartTransformBandWidth = (value: number): string => {
  // 检查输入是否有效
  if (value === null || value === undefined || isNaN(value)) {
    return "-";
  }

  // 确定符号
  const sign = value < 0 ? "-" : "";
  let data = Math.abs(value);

  // 定义单位和对应的阈值
  const units = ["Kbps", "Mbps"];
  let unitIndex = 0;

  // 如果数据大于等于1000 Kbps，则转换为Mbps
  if (data >= 1000) {
    unitIndex = 1;
    data /= 1000;
  }

  // 格式化到两位小数，但如果是整数则不显示小数
  let size = data.toFixed(2).replace(/\.00$/, "");

  // 组合结果
  return `${sign}${size}${units[unitIndex]}`;
};

// export const chartTransformBandWidth = (value: number): string => {
//   if (value === undefined || value === null || typeof value !== "number") {
//     return `-`;
//   }

//   const sign = value > 0 ? "" : "0";
//   const data = Math.abs(value);
//   let size = "";

//   if (data < 1000) {
//     size = data.toFixed(2) + "Kbps";
//   } else if (data < 1000 * 1000) {
//     size = (data / 1000).toFixed(2) + "Mbps";
//   }

//   let sizeStr = size + "";
//   let index = sizeStr.indexOf(".");

//   // 使用 substring 替代 substr
//   if (index !== -1) {
//     let dou = sizeStr.substring(index + 1, index + 3); // 获取小数点后两位
//     if (dou === "00") {
//       return sizeStr.substring(0, index) + sizeStr.substring(index + 3); // 去掉小数点后两位的00
//     }
//   }

//   return `${sign}${size}`;
// };

export const transformChartsTime = (value: number) => {
  if (!value && typeof value !== "number") {
    return `-`;
  }
  const sign = value > 0 ? "" : "0";
  const data = Math.abs(value);
  let size = "";
  if (data < 60) {
    size = `${Math.ceil(data)} ${!!languagePkg && languagePkg["SECOND"]}`;
  } else if (data < 60 * 60) {
    size = `${Math.ceil(data / 60)} ${!!languagePkg && languagePkg["MIN"]}`;
  } else {
    size = `${Math.ceil(data / 60 / 60)} ${
      !!languagePkg && languagePkg["HOUR"]
    }`;
  }
  return `${sign}${size}`;
};
